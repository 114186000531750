<template>
  <div class="container forget-password-page">
<!--    <nav aria-label="breadcrumb">-->
<!--      <ol class="breadcrumb pl-0 mb-0 pb-2">-->
<!--        <li class="breadcrumb-item"><a href="/">{{ $t('breadcrumb.main-page') }}</a></li>-->
<!--        <li class="breadcrumb-item active" aria-current="page">{{ $t('reset-password.title') }}</li>-->
<!--      </ol>-->
<!--    </nav>-->

    <nav class="breadcrumbs-block">
      <ul class="breadcrumbs-list">
        <li class="breadcrumbs-item"><a href="/">Главная</a></li>
        <li class="breadcrumbs-item">{{ $t('reset-password.title') }}</li>
      </ul>
    </nav>

    <div class="row signin-wrapper">
      <div class="col-lg-6 col-md-12 signin-col-logo">
        <div class="signin-logo">
          <img src="/images/qabilet-logo.svg" width="236" height="114" style="max-width: 100%;">
        </div>
      </div>
      <div class="col-lg-6 col-md-12 signin-col-form">
        <div class="forget-password">
          <div class="">
            <div class="forget-password__title">
              {{ $t('reset-password.title') }}
            </div>
            <div class="forget-password__logo">
              <img src="/images/Qabilet-Final-logo.svg">
            </div>
            <div v-if="!smsSended" class="description">
              <form-group :field="$v.phone" :label="$t('signin-form.phone-label')" name="phone" class="mt-4 w-100">
                <el-input
                    :placeholder="$t('signin-form.phone-placeholder')"
                    v-model="phone"
                    @input="enforcePhoneFormat"
                    @blur="onGetIin"
                    autocomplete="off">
                </el-input>
              </form-group>
              <form-group :field="$v.form.iin" :label="$t('signin-form.member-label')" name="iin">
                <el-select v-model="form.iin" :placeholder="$t('signin-form.member-placeholder')" :loading="iinLoading" class="w-100">
                  <el-option
                      v-for="iin in iinArray"
                      @change="$v.form.iin.$touch()"
                      :key="iin.value"
                      :label="iin.label"
                      :value="iin.value">
                  </el-option>
                </el-select>
              </form-group>
              <div class="form-group pt-2">
                <el-button type="primary" @click="sendSms">{{ $t('reset-password.next-btn') }}</el-button>
              </div>
            </div>
            <div v-if="smsSended" class="description">
              <div v-if="phoneVerified" class="w-100">
                <div style="margin-top: 1rem" class="col-12 px-0">
                  <p class="text-primary">{{ $t('signin-form.password-must1') }}</p>
                  <p><img src="/images/done.svg" alt="" class="mr-2"> {{ $t('signin-form.password-must2') }}</p>
                  <p><img src="/images/done.svg" alt="" class="mr-2"> {{ $t('signin-form.password-must3') }}</p>
                  <p><img src="/images/done.svg" alt="" class="mr-2"> {{ $t('signin-form.password-must5') }}</p>
                </div>

                  <form-group class="my-4" :label="$t('references.required-field') + $t('signin-form.password-label')" name="password">
                  <el-input
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      type="number"
                      maxlength="4"
                      :placeholder="$t('reset-password.password-placeholder')"
                      v-model="password"
                      @input="onPasswordInput"
                      show-password
                      autocomplete="off">
                  </el-input>
                  </form-group>


                <form-group  :label="$t('references.required-field') + $t('signin-form.password-confirm-label')" name="password">
                  <el-input
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      type="number"
                      maxlength="4"
                      :placeholder="$t('reset-password.password-confirm-placeholder')"
                      v-model="password_confirmation"
                      @input="onPasswordConfirmInput"
                      show-password
                      autocomplete="off">
                  </el-input>
                </form-group>
                <div class="form-group pt-2">

                  <el-button type="primary" @click="resetPassword">{{ $t('reset-password.recovery-btn') }}</el-button>

                </div>
              </div>
              <div v-else class="w-100">
                <div class="form-group my-4">
                  <label>{{ $t('reset-password.enter-sms-code') }}</label>
                  <el-input
                      :placeholder="$t('reset-password.enter-sms-code-placeholder')"
                      v-model="code"
                      autocomplete="off">
                  </el-input>
                </div>
                <div class="form-group pt-2">
                  <el-button type="primary" @click="verification">{{ $t('reset-password.next-btn') }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import LoginForm from '@/components/LoginForm'
import {mapActions} from "vuex";
import {helpers, minLength, required} from "vuelidate/lib/validators";

const passwordHelper = helpers.regex('passwordHelper', /\d{3}[0-9]/);
export default {
  name: 'Login',
  components: {
    LoginForm
  },
  data(){
    return {
      code: '',
      token: '',
      email: '',
      smsSended: false,
      phoneVerified: false,
      iinLoading: false,
      password: '',
      password_confirmation: '',
      iinArray: [],
      phone: '',
      passwordValid: false,
      submitted:false,
      rules: [
        { active: false, message: this.$t('reset-password.number-required'), regex:/\d{3}[0-9]/ },

        // { active: false, message:"Допустимые символы: ! . - _ ?",  regex:/[!.\-_?]/ },
      ],
      form: {
        phone: '',
        iin: ''
      }
    }
  },

  methods: {
    addOptionsToSelect() {
      this.iinArray = [];
      let data = JSON.parse(sessionStorage.getItem('children'));

      data.forEach(element => {
        let item = {};
        item.label = element.last_name + " " + element.first_name;
        item.value = element.iin;
        this.iinArray.push(item);
      });
      sessionStorage.removeItem('children');
    },
    onGetIin() {
      this.iinLoading = true;
      if (!this.$v.form.phone.$invalid){
        if (this.form.phone != '') {
          this.getIin({
            phone: this.form.phone
          }).then((res) => {
            this.iinLoading = false;
            this.addOptionsToSelect()
          }).catch((e) => {
            this.iinLoading = false;
          })
        }
      }
    },
    enforcePhoneFormat() {
      this.$v.phone.$touch();
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      this.phone = !x[2]
          ? x[1]
          : "+" + x[1] + " (" + x[2] + ")" + (x[3] ? " " + x[3] : "") + (x[4] ? " " + x[4] : "") + (x[5] ? "-" + x[5] : "");
      this.form.phone = x[0];
    },
    sendSms(){
      this.$v.form.iin.$touch()
      if (this.$v.form.iin.$invalid) return
      this.$http.get(window.API_ROOT + '/api/password/reset/sms?iin=' + this.form.iin)
        .then((res) => {
          if (res.body.status == 'success') {
            Vue.toastr({
              message: 'Success',
              description: this.$t('reset-password.sms-sended'),
              type: 'success'
            });
            this.token = res.body.token;
            this.email = res.body.email;
            this.smsSended = true;
          } else {
            Vue.toastr({
              message: 'Error',
              description: res.body.status,
              type: 'error'
            });
          }
        })
        .catch((e) => {

        })
    },
    verification(){
      this.$http.post(window.API_ROOT + '/api/password/reset/check-sms', {
        code: this.code,
        token: this.token
      })
      .then((res) => {
        if (res.body.status == 'success') {
          Vue.toastr({
            message: 'Success',
            description: this.$t('reset-password.phone-verified'),
            type: 'success'
          });
          this.phoneVerified = true;
        } else {
          Vue.toastr({
            message: 'Error',
            description: res.body.status,
            type: 'error'
          });
        }
      })
      .catch((e) => {

        Vue.toastr({
          message: 'Error',
          description: this.$t('reset-password.verification-error'),
          type: 'error'
        });

      })
    },
    resetPassword(){
      if (this.$v.$invalid) return;
      this.$http.post(window.API_ROOT + '/api/password/update/sms', {
        token: this.token,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
        .then((res) => {
          Vue.toastr({
            message: res.body.status,
            description: res.body.message,
            type: res.body.status
          })
          if (res.body.status == 'success') {
            this.$router.push({path: '/signin'})
          }
        })
        .catch((e) => {
          Vue.toastr({
            message: 'Error',
            description: e.body.message,
            type: 'error'
          })
        })
    },
    onPasswordInput(e){
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: 'Error',
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      this.password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    onPasswordConfirmInput(e){
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: 'Error',
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      this.password_confirmation = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    ...mapActions({
      'getIin': 'getIin',
    })
  },
  computed: {
    notSamePasswords () {
      if (this.passwordsFilled) {
        return (this.password !== this.password_confirmation)
      } else {
        return false
      }
    },
    passwordsFilled () {
      return (this.password !== '' && this.password_confirmation !== '')
    },
    passwordValidation () {
      let errors = []
      for (let condition of this.rules) {
        condition.active = condition.regex.test(this.password);
        // if (!condition.regex.test(this.password)) {
        errors.push({
          message: condition.message,
          active: condition.active
        });
        // }
      }
      let error = false;
      Object.entries(errors).forEach(entry => {
        const [key, value] = entry;
        if (value.active == false){
          error = true;
        }
      });
      this.passwordValid = !error;
      return { valid:!error, errors }
    }
  },
  validations: {
    password: { required, passwordHelper, minLength: minLength(4),
      valid: function(value) {
        if(value.length>=4){
          return /\d{3}[0-9]/.test(value)
        }
      }
    },
    password_confirmation: { required, passwordHelper,
      valid: function(value) {
        return this.password === this.password_confirmation
      }},
    phone: {
      required: false,
      minLength: minLength(18)
    },
    form: {
      iin: {
        required,
      },
      phone: {
        minLength: minLength(11)
      },
    }
  }
}
</script>
<style>
.signin-wrapper {
  padding-bottom: 85px;
  padding-top: 35px;
}
.signin-col-logo {
  margin-left: 0;
  margin-right: 33px;
}
.signin-col-form {
  max-width: 414px;
  margin-right: 0;
  margin-left: 33px;
}
.signin-logo {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  height: 377px;
  width: 443px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.text-danger span{
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('/images/close.svg');
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
  background-color: #dc3545;
  float: left;
  border-radius: 24px;
  margin-right: 10px;
}
.text-success span{
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("/images/ok.svg");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
  background-color: #018837;
  float: left;
  border-radius: 24px;
  margin-right: 10px;
}
.el-alert .el-alert__title{
  text-transform: capitalize;
}
.forget-password {
  background: #FFFFFF;
  border: 1px solid rgba(224, 224, 224, 0.15);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 30px;
  position: relative;
  height: max-content;
  width: 384px;
}
.forget-password__title {
  color: #2C2C2C;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 7px;
  position: relative;
}
.forget-password__title:before {
  content: '';
  position: absolute;
  top: 23px;
  right: 0;
  bottom: 0;
  width: 155px;
  left: 0;
  background: linear-gradient(90deg, #31456F 0%, #03C4F7 100%);
}
.forget-password__logo {
  position: absolute;
  top: 20px;
  right: 30px;
}
.forget-password__desc {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #2C2C2C;
  margin-bottom: 17px;
  margin-top: 42px;
}
.forget-password .el-radio__inner {
  width: 16px;
  height: 16px;
}
.forget-password .el-radio__inner::after {
  width: 8px;
  height: 8px;
}

.forget-password-page .breadcrumbs-block {
  margin: 30px 0;
}

.forget-password-page .breadcrumbs-block li {
  color: #343f68;
}

.forget-password-page .breadcrumbs-block ul li {
  border-right: 1px solid #343f68;
}

.forget-password-page .breadcrumbs-block ul li:last-child {
  border-right: none;
}

.forget-password-page .breadcrumbs-block a {
  color: #343f68;
}

@media screen and (max-width: 991px) {
  .signin-logo {
    margin-bottom: 43px;
    width: 100%;
    height: 233px;
  }
  .signin-block {
    width: 100%;
    margin: 0 auto;
    padding: 25px 9px 32px 9px;
  }
  .signin-col-form, .signin-col-logo {
    max-width: 100%;
    margin: 0;
  }
  .signin-wrapper {
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }

}
@media (max-width: 520px) {
  /* .signin-logo {
      width: 237px;
      height: 191px;
  } */
  /* .signin-logo img {
      width: 140px;
  } */
}
@media screen and (max-width: 991px) {
  .signin-logo {
        margin-bottom: 43px;
        width: 100%;
        height: 233px;
    }
}
</style>